import Vue from 'vue'
import './cube-ui'
import App from './App.vue'
import 'amfe-flexible'

import Vuex from 'vuex'
Vue.use(Vuex);

import htmlToPdf from './utils/htmlToPdf'
Vue.use(htmlToPdf)

import vueEsign from 'vue-esign'
Vue.use(vueEsign)

import VueCookies from 'vue-cookies'
Vue.use(VueCookies)
Vue.use(require('vue-faker'));

import store from './store'
import router from './router/router.js'

import { Swiper as SwiperClass, Pagination, Mousewheel, Autoplay } from 'swiper/swiper.esm'
import getAwesomeSwiper from 'vue-awesome-swiper/dist/exporter'
SwiperClass.use([Pagination, Mousewheel, Autoplay])
Vue.use(getAwesomeSwiper(SwiperClass))

Vue.config.productionTip = false

Vue.filter("numFilter", function(value) {
    let tempVal = parseFloat(value).toFixed(3);
    let realVal = tempVal.substring(0, tempVal.length - 1);
    return realVal.replace(/^(-?\d+?)((?:\d{3})+)(?=\.\d+$|$)/, function (all, pre, groupOf3Digital) {
        return pre + groupOf3Digital.replace(/\d{3}/g, ',$&');
      });
});

Vue.filter("numFilter2", function(value) {
    let tempVal = parseFloat(value).toFixed(0);
    let realVal = tempVal.substring(0, tempVal.length);
    return realVal.replace(/^(-?\d+?)((?:\d{3})+)(?=\.\d+$|$)/, function (all, pre, groupOf3Digital) {
        return pre + groupOf3Digital.replace(/\d{3}/g, ',$&');
      });
});

new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app')
