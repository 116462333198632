import { Locale } from 'cube-ui'
export default {
    mixins: [Locale.localeMixin],
    config: {
        'app_name': 'AZ International',
        'domain_name': 'demo.icrown.app',
		'platform_type': 1, //1:現金模式 , 0:信用模式
        'ImgUrl': '/uploads/',
		'min_bet': 1,
		'ApiUrl': '/api',
        'MyIP': '',
		'kfUrl': '', //客服鏈接
        'AndroidUrl': '', //安卓下載連結
        'IOSUrl': '', //IOS下載連結
        'InviteUrl': '', //邀請連結
		'bonus_list': '', //中獎名單
        'vendor_list': {}, //原廠名單
		'dzp_score': 0, //大轉盤消費點數
        'wsURL': "", //web_socket地址
        'cash_type':{ //1:競標, 2:利息 , 3:充值, 4提現, 5提現失敗反款, 6活動 , 7返點, 8扣款
            //0   : 'lang.msg34',
			1   : 'lang.msg19',
			//2   : 'lang.msg30', //任務獎勵
            3   : 'lang.msg31', //存款
            //4   : 'lang.msg32', //提款
            //5   : 'lang.msg149', //提款失敗返款
            6   : 'lang.msg33', //活動
            //7   : 'lang.msg150', //一級任務獎勵分紅
            8   : 'lang.msg175', //扣款
            //9   : 'lang.msg21',
            //10   : 'lang.msg210', //邀請獎勵
            //11   : 'lang.msg212', //二級任務獎勵分紅
            //12   : 'lang.msg213', //三級任務獎勵分紅
            // 13   : 'lang.msg225',
            // 14   : 'lang.msg226',            
            // 15   : 'lang.msg227',            
            // 16   : 'lang.msg228',            
            // 17   : 'lang.msg229',            
            // 18   : 'lang.msg230',            
            //20   : 'lang.msg235',
            //21   : 'lang.msg236',
            //22   : 'lang.msg246',
            //23   : 'lang.msg250',
        },
        'wallet_type':{ //1:競標, 2:利息 , 3:充值, 4提現, 5提現失敗反款, 6活動 , 7返點, 8扣款
            0   : 'lang.msg237',
            1   : 'lang.msg238',
            2   : 'lang.msg239',
        },
        'country':{
              vietnam: '84',
              usa: '1',
              thailand : '66',
              taiwan  : '886',
              spain : '34',
              singapore  : '65',
              pakistan   : '92',
              philippines   : '63',
              nigeria   : '234',
              macao   : '853',
              japan   : '81',
              hongkong   : '852',
              germany   : '49',
              china   : '86',
              brazil    : '55',
              australia    : '61',
              canada    : '1',
              india    : '91',
              qatar    : '974',
              uk    : '44',
                Austria : 		'43',
                Colombia : 		'358',
                Finland : 		'57',
                France : 		'33',
                Honduras : 		'504',
                Italy : 		'39',
                Portugal : 		'351',
                Argentina : 	'54',
                Ecuador : 		'593',
                Ghana : 		'233',
                NorthKorea : 	'850',
                Luxembourg : 	'352',
                Mexico : 		'52',
                Turkey : 		'90',
                Switzerland : 	'41',
                Paraguay : 		'595',
                Netherlands : 	'31',
        },         
    }
}
