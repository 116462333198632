// import axios from 'axios'
// import { apiGetGroup,apiGetUserData,apiGetCfg,apiGetCoin,apiGetType,apiGetBp} from "@/api.js";
import { apiGetCoin} from "@/api.js";
// import { error_func } from '@/util'
export default {
    setUserInfo({commit}, user) {
        commit('setUserInfo',user);
        //this.state.user = user;
    },
    clearUserInfo({ commit }) {
        commit('setUserInfo',{});
    },
    setUserDoc({ commit },DocType) {
        // console.log(this.state.user,DocType)
        // this.state.user['ChkDoc'+DocType] = 1;
        // console.log(this.state.user)
        commit('setUserDoc',DocType);
    },
    setSiteInfo({ commit },Info) {
        // console.log(this.state.user,DocType)
        // this.state.user['ChkDoc'+DocType] = 1;
        // console.log(this.state.user)
        commit('setSiteInfo',Info);
    },
    setVendor({ commit },Info) {
        commit('setVendor',Info);
    },
    getCoin({dispatch})
    {
        clearInterval(this.state.coin_time_line);
        apiGetCoin({
            })
            .then(response => {
                if(response.data.error)
                {
                    //var f=error_func(response.data.func);
                    //f.func();
                    return;
                }
                if(response.data.status==0)
                {
                    return;
                }
                //commit('SetCoinScore',response.data.data);
                dispatch('setUserInfo',response.data.data);
                this.state.coin_time_line= setInterval(() => {
                    dispatch('getCoin');
                }, 10000);
            })
            .catch(error => {
                console.log(error);
            });  
    },
}
