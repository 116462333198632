export default {
	user : state => state.user,
	config : state => state.config,
	betData : state => state.betData,
	betFlag : state => state.betFlag,
	mapByType : state => state.mapByType,
	mapByEgame : state => state.mapByEgame,
	beiShu : state => state.beiShu,
	betCfg : state => state.betCfg,
	betGroupCheck : state => state.betGroupCheck,
	mapByPlay: state => state.mapByPlay,
	mapByGroup: state => state.mapByGroup,
	currentIssue: state => state.currentIssue,
	lastIssue: state => state.lastIssue,
	betCountdown: state => state.betCountdown,
	raceCountdown: state => state.raceCountdown,
	coin_time_line: state => state.coin_time_line,
	mapByGroupToPlay: state => state.mapByGroupToPlay,
	kfUrl: state => state.kfUrl,
	betAmount : state => state.betAmount,
	CartData : state => state.CartData,
}
