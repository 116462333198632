<template>
  <div id="app">
    <CubeView></CubeView>
  </div>
</template>

<script type="text/ecmascript-6">
import CubeView from './components/cube-view.vue'
export default {
    data() {
        return {}
    },
    methods: {

    },
    computed: {

    },
    components: {
        CubeView,
    }
}
</script>