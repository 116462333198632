import { apiThirdIP} from "@/api.js";
export default {
    AddCartData(state,Data)
    {
        this.state.CartData = this.state.CartData.concat(Data);
    },
    setUserInfo(state,user){
        this.state.user = user;
    },
    setUserDoc(state,DocType)
    {
        this.state.user['ChkDoc'+DocType] = 1;
    },
    setSiteInfo(state,Info)
    {
        this.state.config.kfUrl = Info.service;
        this.state.config.AndroidUrl = Info.AndroidUrl;
        this.state.config.IOSUrl = Info.IOSUrl;
        this.state.config.InviteUrl = Info.InviteUrl;
        this.state.config.app_name = Info.APPName;
        this.state.config.wsURL = Info.wsURL;
        apiThirdIP({
            })
            .then(response => {
                if(response)
                {
                    this.state.config.MyIP=response.data.ip
                }else{
                    this.state.config.MyIP="127.0.0.1"
                }
                
            })
            .catch(error => {
                console.log(error);
            });          
    },
    setVendor(state,Info)
    {
        this.state.config.vendor_list = Info;
    },
	// RestChooseBet()
	// {
 //        this.state.betData.splice(0,this.state.betData.length);
 //        this.state.betFlag.splice(0,this.state.betFlag.length);
	// }
}
