import axios from 'axios';
import Qs from 'qs';
import GlobalApp from './store/GlobalApp.js';

const apiRequest = axios.create({
	baseURL:  GlobalApp.config.ApiUrl,
	headers: {
		'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
	},
	transformRequest: [function(data) {      //在請求之前對data傳參進行格式轉換
		data = Qs.stringify(data)
		return data
	}],
});

const apiIpify = axios.create({
	baseURL:  "https://api.ipify.org?format=json",
	headers: {
		'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
	},
	transformRequest: [function(data) {      //在請求之前對data傳參進行格式轉換
		data = Qs.stringify(data)
		return data
	}],
});


export { apiRequest,apiIpify }

//export const apiGetLoginData = () => apiRequest.get('/static/login.json');
//export const apiGetUserData = () => apiRequest.get('/static/user.json');
//export const apiGetCurrentIssue = data => apiRequest.post('/get_current_issue',data);
export const apiThirdIP = data => apiIpify.get('',data); //用第三方取得IP

export const apiCheckLogin = data => apiRequest.post('/user/CheckLogin',data); //檢查用戶狀態
export const apiCheckLetter = data => apiRequest.post('/user/CheckLetter',data); //檢查站內信
export const apiBidLog = data => apiRequest.post('/user/BidLog',data); //預約紀錄
export const apiTransLog = data => apiRequest.post('/user/TransLog',data); //資金紀錄
export const apiProdLog = data => apiRequest.post('/user/ProdLog',data); //預約紀錄
export const apiSafe = data => apiRequest.post('/user/Safe',data); // 获取安全状态
export const apiSafeUp = data => apiRequest.post('/user/SafeUp',data); // 更新安全状态
export const apiSigned = data => apiRequest.post('/user/Signed',data); // 簽到
export const apiMessage = data => apiRequest.post('/user/Message',data); // 獲取站內信
export const apiMessageRead = data => apiRequest.post('/user/MessageRead',data); // 把站內信改成已讀
export const apiBonusLog = data => apiRequest.post('/user/BonusLog',data); // 獲取收益明細
export const apiTransWallet = data => apiRequest.post('/user/TransWallet',data); // 錢包轉帳

export const apiReg = data => apiRequest.post('/index/Reg',data); //註冊
export const apiLogin = data => apiRequest.post('/index/Login',data); //登入
export const apiLogout = data => apiRequest.post('/index/Logout',data); //登出
export const apiBank = data => apiRequest.post('/index/Bank',data); //銀行列表
export const apiPromotion = data => apiRequest.post('/index/Promotion',data); //優惠活動
export const apiNews = data => apiRequest.post('/index/News',data); //新聞中心
export const apiSiteInfo = data => apiRequest.post('/index/SiteInfo',data); //獲取平台設定
export const apiGetCaptcha = data => apiRequest.post('/index/GetCaptcha',data); //取得驗證碼
export const apiSmsSend = data => apiRequest.post('/index/SmsSend',data); //發送短信

export const apiVideoLast = data => apiRequest.post('/index/VideoLast',data); ///視頻最後一筆
export const apiVideoList = data => apiRequest.post('/index/VideoList',data); //視頻列表
export const apiVendorList = data => apiRequest.post('/index/VendorList',data); //原廠列表

export const apiBulletin = data => apiRequest.post('/index/Bulletin',data); // 獲取公告

export const apiRechargeInfo = data => apiRequest.post('/user/RechargeInfo',data); // 获取充值消息
export const apiRechargeBank = data => apiRequest.post('/user/RechargeBank',data); // 提交充值消息
export const apiRechargeThird = data => apiRequest.post('/user/RechargeThird',data); // 提交充值消息
export const apiCash = data => apiRequest.post('/user/Cash',data); // 提交提款消息
export const apiRechargeLog = data => apiRequest.post('/user/RechargeLog',data); // 獲取充值日誌
export const apiCashLog = data => apiRequest.post('/user/CashLog',data); // 獲取提款日誌

export const apiTeam = data => apiRequest.post('/agent/Team',data); // 獲取代理團隊
export const apiInvite = data => apiRequest.post('/agent/Invite',data); // 獲取邀請碼
export const apiInCome = data => apiRequest.post('/agent/InCome',data); // 獲取收入列表
export const apiMemberDetial = data => apiRequest.post('/agent/MemberDetial',data); // 獲取用戶信息
export const apiAgentQR = data => apiRequest.post('/agent/AgentQR',data); // 獲取代理邀請碼
export const apiAgentRebate = data => apiRequest.post('/agent/AgentRebate',data); // 獲取代理邀請碼
export const apiAgentTransLog = data => apiRequest.post('/agent/AgentTransLog',data); // 獲取交易紀錄
export const apiTeamMember = data => apiRequest.post('/agent/TeamMember',data); // 獲取下級資料

export const apiProd = data => apiRequest.post('/game/ProdAz199',data); //產品列表
export const apiBid = data => apiRequest.post('/game/BidAz199',data); //下標
export const apiSale = data => apiRequest.post('/game/SaleAz199',data); //出售
export const apiBidUp = data => apiRequest.post('/game/BidUp',data); //更新狀態
export const apiBidClose = data => apiRequest.post('/game/BidClose',data); //關閉單子
export const apiBidUpdate = data => apiRequest.post('/game/BidUpdate',data); //更新單子狀態
export const apiGameList = data => apiRequest.post('/game/GameList',data); //牌桌名單

//export const apiGetCfg = data => apiRequest.post('/get_cfg2',data);
//export const apiBet2 = data => apiRequest.post('/bet2',data); // 信用玩法
export const apiGetCoin = data => apiRequest.post('/user/CheckLogin',data); // 獲取餘額
//export const apiGetBetLog = data => apiRequest.post('/bet_log',data); // 獲取注單

//export const apiGetCfg = data => apiRequest.post('/index/get_cfg',data);