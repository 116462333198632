import GlobalApp from './GlobalApp'
export default {
    config: GlobalApp.config,
	user:{
		ID : 0,
		Username : '',
        Coin:0,
        Signed:0,
        Verified:0,
        CountSigned:0,
        ChkDoc16:0,
        ChkDoc17:0,
        ChkDoc18:0,
        ChkDoc19:0,
        ChkDoc20:0,
        ChkDoc21:0,
        ChkDoc22:0,
        ChkDoc23:0,
    },
}
