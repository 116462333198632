export default {
  cancel: '取消',
  confirm: '確認',
  ok: '確定',
  prev: '上一步',
  next: '下一步',
  selectText: '請選擇',
  now: '現在',
  selectTime: '選擇時間',
  today: '今日',
  formatDate: 'M月D日',
  hours: '點',
  minutes: '分',
  validator: {
    required: '此為必填項',
    type: {
      string: '請輸入字元',
      number: '請輸入數字',
      array: '數據型別應為陣列',
      date: '請選擇有效日期',
      email: '請輸入有效郵箱',
      tel: '請輸入有效的手機號碼',
      url: '請輸入有效網址'
    },
    min: {
      string: '至少輸入 {{config}} 位字元',
      number: '不得小於 {{config}}',
      array: '請選擇至少 {{config}} 項',
      date: '請選擇 {{config | toLocaleDateString("yyyy/MM/dd/")}} 之後的時間',
      email: '至少輸入 {{config}} 位字元',
      tel: '至少輸入 {{config}} 位字元',
      url: '至少輸入 {{config}} 位字元'
    },
    max: {
      string: '請勿超過 {{config}} 位字元',
      number: '請勿大於 {{config}}',
      array: '最多選擇 {{config}} 項',
      date: '請選擇 {{config | toLocaleDateString("yyyy/MM/dd/")}} 之前的時間',
      email: '請勿超過 {{config}} 位字元',
      tel: '請勿超過 {{config}} 位字元',
      url: '請勿超過 {{config}} 位字元'
    },
    len: {
      string: '請輸入 {{config}} 位字元',
      number: '長度應等於 {{config}}',
      array: '請選擇 {{config}} 項',
      date: '請選擇 {{config | toLocaleDateString("yyyy/MM/dd/")}} 之前的時間',
      email: '請輸入 {{config}} 位字元',
      tel: '請輸入 {{config}} 位字元',
      url: '請輸入 {{config}} 位字元'
    },
    pattern: '格式錯誤',
    custom: '未通過校驗',
    notWhitespace: '空白內容無效'
  },
  //#### iris msg ####
  lang:{
      msg1: '註冊成功',
      msg2: '會員註冊',
      msg3: '帳號',
      msg4: '手機號',
      msg5: '密碼',
      msg6: '確認密碼',
      msg7: '邀請碼',
      msg8: '記住帳號',
      msg9: '登錄',
      msg10: '註冊',
      msg11: '會員登錄',
      msg12: '時間',
      msg13: '價值',
      msg14: '鏟子',
      msg15: '增值',
      msg16: '金額',
      msg17: '完成',
      msg18: '狀態',
      msg19: '訂閱扣款',
      msg20: '購買中',
      msg21: '出售',
      msg22: '已售出',
      msg23: '確認接單',
      msg24: '確認',
      msg25: '取消',
      msg26: '購買成功',
      msg30: '任務獎勵',
      msg31: '存款',
      msg32: '提款',
      msg33: '活動',
      msg34: '全部',
      msg35: '暫無相關紀錄',
      msg36: '錢包紀錄',
      msg37: '任務紀錄',
      msg38: '查詢',
      msg39: '今天',
      msg40: '7天',
      msg41: '30天',
      msg42: '請選擇',
      msg43: '取消',
      msg44: '確定',
      msg45: '搜尋',
      msg46: '重置',
      msg47: '開始時間',
      msg48: '結束時間',
      msg50: '日期快捷',
      msg51: '錢包紀錄',
      msg52: '任務紀錄',
      msg53: '商品清單',
      msg54: '提款紀錄',
      msg55: '存款紀錄',
      msg56: '安全設置',
      msg57: '幫助中心',
      msg58: '優惠活動',
      msg59: '站內消息',
      msg60: '登出',
      msg61: '代理中心',
      msg62: '利息已派發',
      msg63: '利息尚未派發',
      msg64: '現值',
      msg65: '預約日期',
      msg66: '計息日期',
      msg67: '持有商品',
      msg68: '登錄密碼',
      msg69: '提款密碼',
      msg70: '手機號碼',
      msg71: '電子郵件',
      msg72: '銀行卡',
      msg73: '未設置',
      msg74: '原登錄密碼',
      msg75: '新登錄密碼',
      msg76: '確認密碼',
      msg77: '6位以上字母加數字',
      msg78: '修改成功',
      msg79: '新密碼不能與原密碼相同',
      msg80: '密碼與確認密碼不一致',
      msg81: '原提款密碼',
      msg82: '新提款密碼',
      msg83: '綁定手機',
      msg84: '請輸入手機號',
      msg85: '如您無法自助修改,聯繫線上客服獲得幫助',
      msg86: '請聯繫客服重置手機號',
      msg87: '請聯繫客服重置郵箱',
      msg88: '郵箱地址',
      msg89: '請輸入郵箱地址',
      msg90: '請聯繫客服重置銀行卡',
      msg91: '銀行名',
      msg92: '銀行卡號',
      msg93: '請輸入銀行卡號',
      msg94: '帳戶名',
      msg95: '會員中心',
      //msg96: '存款',
      msg97: '選擇渠道',
      msg98: '複製',
      msg99: '請輸入金額',
      msg100: '提交成功',
      msg101: '訂單編號',
      msg102: '離開此頁',
      msg103: '銀行匯款',
      msg104: '三方支付',
      msg105: '附言',
      msg106: '提交中...',
      msg107: '複製成功',
      msg108: '爲了您的資金安全，完善以下數據才可提款',
      msg109: '設定提款密碼',
      msg110: '已設定',
      msg111: '設定銀行卡',
      msg112: '可提金額',
      msg113: '提款金額',
      msg114: '請輸入提款密碼',
      msg115: '下一步',
      msg116: '回上一步',
      msg117: '申請成功',
      msg118: '請及時檢視資金記提款錄申請狀態',
      msg119: '離開此頁',
      msg120: '下拉重新整理',
      msg121: '安全認證',
      msg122: '輸入金額',
      msg123: '申請成功',
      msg124: '存',
      msg125: '型別',
      msg126: '方式',
      msg127: '渠道',
      msg128: '申請時間',
      msg129: '更新時間',
      msg130: '訂單編號',
      msg131: '提',
      msg132: '申請中',
      msg133: '成功到账',
      msg134: '存款失败',
      //msg135: '成功',
      //msg136: '失败',
      msg137: '系统公告',
      msg138: '站内信件',
      msg139: '成功登出',
      msg140: '我的團隊',
      msg141: '邀請碼',
      msg142: '收益明細',
      msg143: '帳號',
      msg144: '下級人數',
      msg145: '註冊日期',
      msg146: '登入日期',
      msg147: '註冊起始',
      msg148: '註冊結束',
      msg149: '提款失敗返款',
      msg150: '一級任務獎勵分紅',
      msg151: '已讀',
      msg152: '未讀',
      msg153: '會員帳號',
      msg154: '輸入會員帳號',
      msg155: '掃碼註冊',
      msg156: '邀請碼',
      msg157: '邀請鏈接',
      msg158: '總註冊人數',
      msg159: '總任務獎勵分紅',
      msg160: '首頁',
      msg161: '日誌',
      msg162: '存款',
      msg163: '客服',
      msg164: '會員',
      msg165: '新聞中心',
      msg166: '視頻中心',
      msg167: '天',
      msg168: '確認出售',
      msg169: '提交成功',
      msg170: '出售中',
      msg171: '交易中心',
      msg172: '到期',
      msg173: '同意協議',
      msg174: '下載協議',
      msg175: '扣款',
      msg176: '時裝  華倫天奴',
      msg177: '名畫—收藏',
      msg178: '珠寶—珍珠',
      msg179: '奧運村',
      msg180: '百貨商城',
      msg181: '世界杯',
      msg182: '房地產',
      msg183: '石油',
      msg184: '3個月總購買額',
      msg185: '貨幣地址',
      msg186: 'Android APP',
      msg187: 'IOS APP',
      msg188: '同意各項合約',
      msg189: '註冊合約',
      msg190: '稅 30%',
      msg191: '請重新上傳圖片',
      msg192: '只能上傳一張圖片',
      msg193: '圖片不能超過10M',
      msg194: '收益',
      msg195: '密碼(6位以上字母加數字)',
      msg196: '確認密碼(6位以上字母加數字)',
      msg197: 'x',
      msg198: '月',
      msg199: '實名認證',
      msg200: '真實姓名',
      msg201: '身分證號',
      msg202: '身分證件上傳',
      msg203: '只能上傳5張圖片',
      msg204: '請聯繫客服重置',
      msg205: '請簽名',
      msg206: '重新簽名',
      msg207: '同意簽名',
      msg208: '忘記密碼?',
      msg209: '請填入全部欄位',
      msg210: '下級充值回饋',
      msg211: '下級任務獎勵分紅',
      msg212: '二級任務獎勵分紅',
      msg213: '三級任務獎勵分紅',
      msg214: '一級收益',
      msg215: '二級收益',
      msg216: '三級收益',
      msg217: '總收益',
      msg218: '總人數',
      msg219: '一級人數',
      msg220: '二級人數',
      msg221: '三級人數',
      msg222: '收益明細',
      msg223: '自由交易收益',
      msg224: '固定資產收益',
      msg225: '自由交易利息',
      msg226: '固定資產利息',
      msg227: '自由交易購買',
      msg228: '固定資產購買',
      msg229: '自由交易出售',
      msg230: '固定資產出售',
      msg231: '出款成功',
      msg232: '拒絕',
      msg233: '請輸入銀行名稱',
      msg234: '請上傳身分證正面照,身分證反面照',
      msg235: '登錄獎勵',
      msg236: '資金轉帳',
      msg237: '錢包額度',
      msg238: '自由交易錢包',
      msg239: '固定交易錢包',
      msg240: '轉出錢包',
      msg241: '轉入錢包',
      msg242: '轉帳成功',
      msg243: '簽到',
      msg244: '簽到成功',
      msg245: '已簽到',
      msg246: '簽到獎勵',
      msg247: '連續簽到',
      msg248: '獲得簽到獎勵',
      msg249: '身份證信息填寫錯誤',
      msg250: '利息發放',
      msg251: '輸入圖片數字',
      msg252: '輸入短信驗證碼',
      msg253: '發送短信',
      msg254: '短信已發送',
      msg255: '任務獎勵',
      msg256: '完成任務',
      msg257: '複製連結',
      msg258: '任務連結已複製至剪貼簿,請前往完成任務',
      msg259: '開啟連結',
      msg260: '關閉任務',
      msg261: '上傳截圖',
      msg262: '上傳成功',
      msg263: '請等待客服審核',
      msg264: '任務已關閉',
      msg265: '關閉任務不會恢復接單次數,確認關閉?',
      msg266: '等級',
      msg267: '可接單次數',
      msg268: '上傳匯款證明',
      msg269: '若使用農會,請在卡號後方輸入農會名稱及農會代號',
      msg270: '接單中心',
      msg271: '桌號',
      msg272: '局號',
      
      err1: '密碼與確認密碼不一致',
      err2: '帳號格式不正確',
      err3: '沒有更多數據',
      err4: '金額請輸入數字',
      err5: '金額範圍 ',
      err6: '帳號格式不正確',
      err7: '提交失敗,請重新整理頁面,重新提交存款單',
      err8: '金額不足',
      err9: '請上傳圖片',
      err10: '請輸入金額',
      err11: '轉出轉入錢包不能相同',
  },
  prod:{
      item1: '時間',
      item2: '價值',
      item4: '利息',
      item5: '我要投資',
      item6: '任務中心',
      item7: '固定資產',
      item8: '已購買',
      item9: '接單',
      item10: '會員中心',
      item11: '聯絡客服',
      item12: '入門',
      item13: '進階',
      item14: '白銀',
      item15: '黃金',
      item16: '未完成',
      item17: '已完成',
      item18: '失敗',
      item19: '審核中',
  },
  recharge:{
      msg1: '溫馨提示',
      msg2: '為確保您的款項及時到帳，請您留意以下幾點：',
      msg3: '若您支付過程中遇到問題且未完成支付，請重新下單。',
      msg4: '若您支付遇到困難，聯繫線上客服獲得幫助。',
      msg5: '請及時檢視資金記提款錄申請狀態',
  },
  // country:{
  //     vietnam: '84',
  //     usa: '1',
  //     thailand : '66',
  //     taiwan  : '886',
  //     spain : '34',
  //     singapore  : '65',
  //     pakistan   : '92',
  //     philippines   : '63',
  //     nigeria   : '234',
  //     macao   : '853',
  //     japan   : '81',
  //     hongkong   : '852',
  //     germany   : '49',
  //     china   : '86',
  //     brazil    : '55',
  //     australia    : '61',
  //     canada    : '1',
  //     india    : '91',
  //     qatar    : '974',
  //     uk    : '44',
  // },
  country:{
      vietnam: '越南',
      usa: '美國',
      thailand : '泰國',
      taiwan  : '台灣省',
      spain : '西班牙',
      singapore  : '新加坡',
      pakistan   : '巴基斯坦',
      philippines   : '菲律賓',
      nigeria   : '尼日利亞',
      macao   : '澳門',
      japan   : '日本',
      hongkong   : '香港',
      germany   : '德國',
      china   : '中國',
      brazil    : '巴西',
      australia    : '澳大利亞',
      canada    : '加拿大',
      india    : '印度',
      qatar    : '卡塔爾',
      uk    : '英國',
        Austria : 	'奧地利',
        Colombia : 	'哥倫比亞',
        Finland : 	'芬蘭',
        France : 	'法國',
        Honduras : 	'洪都拉斯',
        Italy : 	'意大利',
        Portugal : 	'葡萄牙',
        Argentina : '阿根廷',
        Ecuador : 	'厄瓜多爾',
        Ghana : 	'加納',
        NorthKorea : '朝鮮',
        Luxembourg : '盧森堡',
        Mexico : 	'墨西哥',
        Turkey : 	'土耳其',
        Switzerland :'瑞士',
        Paraguay : 	'巴拉圭',
        Netherlands :   '荷蘭', 

  }   
}
