import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
const router = new Router({
	//mode: 'history',
	routes: [
		{
            path: '/',
            component: () => import('@/views/layout/Default.vue'),
            children: [
                {
                    path: '',
                    name: 'home',
                    component: () => import('@/views/index/index.vue'),
                },
                {
                    path: 'reg/:code?',
                    name: 'reg',
                    component: () => import('@/views/index/reg.vue'),
                },
                {
                    path: 'login',
                    name: 'login',
                    component: () => import('@/views/index/login.vue'),
                },
                {
                    path: 'news',
                    name: 'news',
                    component: () => import('@/views/index/news.vue'),
                },
                {
                    path: 'video',
                    name: 'video',
                    component: () => import('@/views/index/video.vue'),
                },
                {
                    path: 'game',
                    name: 'game',
                    component: () => import('@/views/game/index.vue'),
                },
            ]
		},
		{
			path: '/user',
			component: () => import('@/views/layout/Default.vue'),
			children: [
				{
					path: 'bidlog',
					component: () => import('@/views/user/bidlog.vue'),
				},
				{
					path: 'index',
					component: () => import('@/views/user/index.vue'),
				},
			]
		},
	]
})
// router.beforeEach((to, from, next) => {
// 	var regex_user = /^\/user\//i;
// 	if (to.path.search(regex_user) === -1) {
// 		next();
// 	}else
// 	{
// 		if(router.app.$options.store.getters.user.Username=='')
// 		{
// 			return next({path: "/"});
// 		}
// 		next();
// 	}
// })
export default router;
